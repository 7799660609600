// extracted by mini-css-extract-plugin
export var alignLeft = "x_p8 d_fp d_bG d_dv";
export var alignCenter = "x_bP d_fq d_bD d_dw";
export var alignRight = "x_p9 d_fr d_bH d_dx";
export var alignColumnLeft = "x_q7 d_fs d_bN d_dv";
export var alignColumnCenter = "x_q8 d_ft d_bP d_dw";
export var alignColumnRight = "x_q9 d_fv d_bQ d_dx";
export var milestonesContainer = "x_sd d_dW";
export var milestonesContainerFull = "x_sf d_dT";
export var milestonesSubtitle = "x_mf d_mf d_cs";
export var milestonesComponentWrapper = "x_mb d_mb d_cv";
export var compContentWrapper = "x_rd d_mc d_bz d_bJ d_bM d_bF d_c7 d_H";
export var milestonesMainHeader = "x_l8 d_l8 d_w d_cr";
export var milestonesSubHeader = "x_l9 d_l9 d_w";
export var milestonesComponentWrapperRow = "x_sg d_H";
export var milestonesBlockWrapper = "x_sh";
export var milestonesTextBlockWrapper = "x_sj";
export var milestonesComponentHeader = "x_md d_md d_w d_cr";
export var milestonesComponentParagraph = "x_mj d_mj d_w";
export var btnWrapper = "x_d2 d_bz d_ck";
export var btnWrapperCards = "x_rg d_bz d_cd";
export var imageWrapper = "x_sk d_cs d_bz";
export var Title1Small = "x_r4 C_r4 C_sJ C_sK";
export var Title1Normal = "x_r5 C_r5 C_sJ C_sL";
export var Title1Large = "x_r6 C_r6 C_sJ C_sM";
export var Title2Small = "x_sl C_sl C_sJ C_sN";
export var Title2Normal = "x_sm C_sm C_sJ C_sP";
export var Title2Large = "x_sn C_sn C_sJ C_sQ";
export var SubtitleSmall = "x_q3 C_q3 C_sJ C_sV";
export var SubtitleNormal = "x_q4 C_q4 C_sJ C_sW";
export var SubtitleLarge = "x_q5 C_q5 C_sJ C_sX";
export var Subtitle2Small = "x_sp C_sp C_sJ C_sY";
export var Subtitle2Normal = "x_sq C_sq C_sJ C_sZ";
export var Subtitle2Large = "x_sr C_sr C_sJ C_s0";
export var BodySmall = "x_r7 C_r7 C_sJ C_s1";
export var BodyNormal = "x_r8 C_r8 C_sJ C_s2";
export var BodyLarge = "x_r9 C_r9 C_sJ C_s3";
export var exceptionLineHeight = "x_ss C_s9";