// extracted by mini-css-extract-plugin
export var articleWrapper = "q_qr";
export var articleText = "q_qs d_dv d_cs";
export var header = "q_qt d_Z d_w";
export var headerImageWrapper = "q_qv d_bd d_0 d_8 d_7 d_4 d_9 d_bk d_bR";
export var headerGradient = "q_qw d_bd d_0 d_8 d_7 d_4 d_9";
export var headerGradientOverlay = "q_qx d_bd d_0 d_8 d_7 d_4 d_9";
export var headerContentWrapper = "q_qy d_bz d_bP d_bD d_bJ d_bl d_Z";
export var headerContentWrapperAlt = "q_qz d_bz d_bP d_bD d_bJ d_bl d_Z";
export var contentWrapper = "q_mS d_w";
export var dateTag = "q_qB d_cf d_cr d_dq d_w";
export var icon1 = "q_qC d_cP";
export var icon2 = "q_qD d_cP d_cJ";
export var tagBtn = "q_qF d_b1 d_cD d_dp d_dB";
export var headerText = "q_qG d_w d_cf d_cs d_bW";
export var center = "q_qH d_dw";
export var videoIframeStyle = "q_qJ d_d5 d_w d_H d_by d_b1 d_R";
export var articleImageWrapper = "q_qK d_cs d_Z";
export var articleImageWrapperIcon = "q_qL d_cs d_Z";
export var articleRow = "q_qM d_bD";
export var quoteWrapper = "q_gQ d_bC d_bP d_cs";
export var quoteBar = "q_qN d_H";
export var quoteText = "q_qP";
export var authorBox = "q_qQ d_w";
export var authorRow = "q_qR d_bD d_bW d_cy";
export var separator = "q_qS d_w";
export var line = "q_fk d_w d_fk d_cY d_cy";
export var authorImage = "q_ln d_b7 d_Z d_w d_ct";
export var authorText = "q_qT d_ch d_ct";
export var masonryImageWrapper = "q_qV";
export var bottomSeparator = "q_qW d_w d_cy";
export var linksWrapper = "q_qX d_ch d_dv";
export var comments = "q_qY d_bD d_cy";
export var sharing = "q_qZ d_bz d_bD d_bJ";
export var shareText = "q_q0 d_w d_dw";
export var icon = "q_q1";
export var customRow = "q_qb d_bD d_Z";
export var text = "q_q2";
export var SubtitleSmall = "q_q3";
export var SubtitleNormal = "q_q4";
export var SubtitleLarge = "q_q5";