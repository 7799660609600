// extracted by mini-css-extract-plugin
export var lbContainerOuter = "G_vy";
export var lbContainerInner = "G_vz";
export var movingForwards = "G_vB";
export var movingForwardsOther = "G_vC";
export var movingBackwards = "G_vD";
export var movingBackwardsOther = "G_vF";
export var lbImage = "G_vG";
export var lbOtherImage = "G_vH";
export var prevButton = "G_vJ";
export var nextButton = "G_vK";
export var closing = "G_vL";
export var appear = "G_vM";